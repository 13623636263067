import React from 'react';

function Title() {
    return (
        <h1 className="title">Guy Ginton:
            <br />Digital Dreamer
        </h1>
    );
}

export default Title;
