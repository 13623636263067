let timelineElements = [
    {
        id: 10,
        title: "Reichman University: Global MBA (Innovation & Entrepreneurship)",
        location: "Herzliya, Israel",
        description:
            "Focus on business methodologies and interdisciplinary innovation. Improve mgmt, analytic, finance, marketing, legal and other skills.",
        date: "Nov 2022 - Jun 2024",
        icon: "school",
        buttonText: "University",
        link: "https://www.runi.ac.il/en/schools/rris/graduate/global-mba"
    },
    {
        id: 9,
        title: "Base: Technical Support Lead",
        location: "Kfar Saba, Israel",
        description:
            "Managed client relations and all support issues. Built knowledge base, troubleshoot guides, procedures, automation to facilitate quicker ticket turnaround. Assisted with technical design and proofreading.",
        date: "Feb 2022 - Present",
        icon: "work",
        buttonText: "Company",
        link: "https://base.ai/"
    },
    {
        id: 8,
        title: "KeepOlim: Web Manager",
        location: "Israel",
        description:
            "I manage the website for KeepOlim, a nonprofit that helps immigrants integrate into Israel. I managed 3 interns who assisted with content management, including social media.",
        date: "Jan 2023 - Present",
        icon: "work",
        buttonText: "KeepOlim",
        link: "https://keepolim.org/"
    },
    {
        id: 7,
        title: "Jeeng: Full Stack Developer",
        location: "Tel Aviv, Israel",
        description:
            "I was an intern at Jeeng, then asked to be their first developer after Jeeng raised funds. Worked with Vue.js, GraphQL, BigQuery, PostgreSQL and memSQL (Single Store). Wrote unit tests with Mocha and Chai. Greatly involved with data management, cost reductions and overseeing schema changes. Previously used Hitachi's Pentaho platform, later Google Data Studio and Looker, to visualize data.",
        date: "Dec 2019 - Jan 2022",
        icon: "work",
        buttonText: "Company",
        link: "https://www.jeeng.com/"
    },
    {
        id: 6,
        title: "OpenWeb: Technical Support Engineer",
        location: "Tel Aviv, Israel",
        description:
            "Built a Chrome extension (vanilla JS) to grab elements from the DOM, to easily debug web integration. Helped account managers and clients answer technical questions. Assisted in QA and infrastructure rollouts. Created test pages.",
        date: "Apr 2019 - Dec 2019",
        icon: "work",
        buttonText: "Company",
        link: "https://www.openweb.com/"
    }, 
    {
        id: 5,
        title: "Jeeng: Full Stack Developer",
        location: "Tel Aviv, Israel",
        description:
            "Wrote using Vue.js and Vuetify paired with Apollo, GraphQL and Node.js. Worked with push notifications through Firebase and FE tested with Cypress. Jeeng's software analyzes clients' data and matches relevant content to users.",
        date: "Jan 2019 - Apr 2019",
        icon: "work",
    },
    {
        id: 4,
        title: "Israel Tech Challenge: Coding Bootcamp",
        location: "Tel Aviv, Israel",
        description:
            "Israel Tech Challenge, or ITC, is an intensive Full-Stack Development program. It consists of 3 months of class studies, 10+ hours a day, followed by a 2-month internship in the hi-tech world.",
        date: "Oct  2018 - Feb 2019",
        icon: "school",
        buttonText: "Course",
        link: "https://www.itc.tech/"
    },   
    {
        id: 3,
        title: "ESAC Inc: Project Manager",
        location: "Rockville, Maryland",
        description:
            "Corresponded with measure developers and CMS in efforts to develop clinical standards - Healthcare IT -  used in the “Electronic Submission of Medical Documentation” and “Electronic Clinical Quality Measures” project.",
        date: "Nov 2015 - Sep 2016",
        icon: "work",
        buttonText: "Company",
        link: "https://www.icf.com/work/federal-health/it"
    },
    {
        id: 2,
        title: "SetMine: Project Manager, Lead PR",
        location: "Hollywood, Florida",
        description:
            "Managed the artist side of a streaming service written in JS. Tasks included artist outreach, managing social media accounts, overseeing development, uploading content to MySQL, creating marketing plans and press kits.",
        date: "May 2015 - May 2016",
        icon: "work",
        buttonText: "View FB",
        link: "https://www.facebook.com/SetmineApp/"
    },
    {
        id: 1,
        title: "University of Florida: Honors Student",
        location: "Gainesville, Florida",
        description:
            "Studied neuroscience and economics, averaging a 3.5 GPA among 220+ credits. Conducted a study published in the journal Appetite. Held leadership roles in Neuroscience Club, Libertarian Club, Ad Society and more.",
        date: "Aug 2010 - May 2014",
        icon: "school",
        buttonText: "View Study",
        link: "https://pubmed.ncbi.nlm.nih.gov/24667154/"
    }
];

export default timelineElements;